.left-aside {
  .my-h3 {
    font-size: 20px;
    @include top-gradient; }
  p.small {
    font-size: 0.9em; }
  .distinguish {
    background-image: linear-gradient(90deg, #ff419e, #9968ea);
    color: #fff;
    cursor: pointer; }
  .calendar td {
    padding: 0;
    width: 40px;
    text-align: center;
    height: 40px;
    border: 2px solid #079cd6;
    vertical-align: middle; }
  .uk-flex {
    .flex-padding {
      @media (max-width: 960px) {
        padding-left: 10px;
        padding-right: 10px; }

      @media (max-width: 640px) {
        padding-left: 0;
        padding-right: 0; } } } }
