.news-section {
  h3 {
    font-weight: bold;
    font-size: 1em;
    color: $top-gradient-2!important;
    line-height: 1.2em;
    margin-bottom: 0.5em!important;
    @media (max-width: 640px) {
      font-size: 1em; } }
  .uk-card-media-bottom {
    position: absolute;
    bottom: 0; }
  em {
    color: #9e9e9e; }
  .uk-label {
    color: #fff;
    text-transform: unset;
    position: absolute;
    top: 10px;
    right: 10px;
    background: RGB(156 156 156 / 51%);
    border-radius: 10px;
    z-index: 2; }


  .uk-label:hover {
    background: #9c9c9c;
    transition: background .4s cubic-bezier(.23,1,.32,1);
    //background: none
 }    //background-image: linear-gradient(90deg, $top-gradient-1, $top-gradient-2)
  img {
    width: 100%; }
  .uk-card-media-top {
    width: 100%; } }
