header {
  background: $bd2;
  .logo {
    img {}
    @media (max-width: 640px) {
      max-width: 80px;
      height: auto; } }
  .top-head-h1 {
    font-weight: 900!important;
    display: block;
    font-size: 30px;
    line-height: 1.2em;
    @include top-gradient;
    @media (max-width: 640px) {
      font-size: 1em;
      margin-left: 20px;
      display: block; } }

  .top-head-h4 {
    font-weight: bold;
    font-size: 0.8em;
    color: #272727;

    @media (max-width: 640px) {
      font-size: 0.7em;
      margin-left: 20px;
      display: none; } }
  .top-menu ul li a {
    color: #fff; }
 }  //background: $bg-three
