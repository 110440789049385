// Call mixin for add font to project
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@include font('Roboto Regular', 'roboto-regular');

// Create mixin for quick fonts applying
@mixin font-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400; }


html,
body {
  width: 100%;
  height: 100%;
  background: #fff!important; }

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;

  color: $color;
  @include font-regular; }

h1 {
  @include font-regular; }
h2 {
  @include font-regular;
  color: $bg-one; }

.border-rounded-20 {
  border-radius: 20px; }
.link-section {
  a {
    display: flex!important;
    align-items: center;
    font-size: 0.8em;
    color: rgba(0,0,0,.54);
    transition: .3s ease-in-out;
    &:hover {
      color: rgba(0,0,0,.8); } }
  li {
    @media (max-width: 640px) {
      padding-left: 0!important; } } }
.adress {
  font-size: 0.8em; }

blockquote {
  box-shadow: $gradient-1 3px 0px 0px 0px inset;
  padding-left: 20px;
  cite {
    font-style: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: #868686; } }
.uk-button-primary {
  background: $gradient-1;
  &:hover {
   background:  $gradient-2!important; } }
.branches-section {
  background: $bd2; }
a {
  color: $top-gradient-2;
  &:hover {
    color: #079cd6;
    text-decoration: none;
    transition: all 0.3ms; } }


.uk-table th {
  color: #111; }

.uk-cover {
  top: 0;
  transform: translate(-50%,0%); }

table {
  border-spacing: 0;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  td, th {
    border-top: 1px solid #e5e5e5;
    padding: 20px;
    border-left: 1px solid #e5e5e5; } }

figcaption {
    margin: 1em;
    text-align: center;
    font-style: italic; }

.ecp {
  background: #2f7c9a;
  color: #fff;
  padding: 3px 9px;
  font-size: 0.9em;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer; }
.uk-offcanvas-bar {
  .none-left-menu {
    display: none!important; } }
.ecp-card {
  font-size: 0.9em;
  p {
    color: #111; } }

.color-bg-one {
  color: $bg-one; }
