.footer {
  background: $bd2;

  p, .adress, a {
    color: #333!important; }
  .uk-nav {
    ul {
      margin-bottom: 20px;
      li {
        a {
         font-size: 0.8em;
         margin-bottom: 8px;
         margin-right: 20px;
         @media (max-width: 960px) {
           font-size: 0.7em; } } } } }

  .footer-bottom {
    background: $bg-one;
    a {
      color: #fff!important; } } }

