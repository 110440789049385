// absolute element centred
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%); }

// fast media-query with max-width
// usage:
// +m(768)
@mixin m($width) {
  @media only screen and (max-width: $width+ "px") {
    @content; } }

// fast font-family
// usage:
// +font(black, cytiapro-black-webfont)
@mixin font($alias, $name) {
  @font-face {
    font-family: $alias;
    font-weight: normal;
    font-style: normal; } }
@mixin top-gradient {
  color: #3a7fe7;
  background-image: linear-gradient(90deg, #66db6c, #2f7c9a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }
