.top-menu {
  background: $bg-one!important;
  //height: 50px
  //line-height: 50px
  //text-align: center

  a,
  &:visited {
    min-height: 50px!important;
    color: white;
    font-weight: bold;
    -webkit-transition: color .4s cubic-bezier(.23,1,.32,1);
    transition: color .4s cubic-bezier(.23,1,.32,1);
    .uk-social {
      height: 22px; }
    .fb {
      height: 18px; } }

  a:hover,
  a:focus {
    //text-decoration: underline
    -webkit-transition: color .4s cubic-bezier(.23,1,.32,1);
    transition: color .4s cubic-bezier(.23,1,.32,1);
    color: $bg-three!important;
    outline: 0; }

  .uk-navbar-toggle {
    color: #fff; } }
.uk-offcanvas-bar {
  background: #154152;
  ul {
    li {
      a {
        color: #fff!important;
        &:hover {
          transition: color .4s cubic-bezier(.23,1,.32,1);
          color: #66db6c!important; } } } } }



