// BG COLORS
$bg-one:  #bc86b8;
$bg-two: #ffd8d8;
$bg-three: #f5f5f6;
$color: #222;

// SIZES
$container: 1140px;
$top-gradient-1: #66db6c;
$top-gradient-2: #2f7c9a;

$gradient-1: #ff419e;
$gradient-2: #9968ea;

$bd2:   #F0EAEF;

